import {Draft, PayloadAction} from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import {initialState} from '.';
import {diarioVentasSliceType} from './diario-ventas.type';

const setDate = (
  state: Draft<diarioVentasSliceType>,
  actions: PayloadAction<{type: 'inicial' | 'final'; date: Date}>,
) => {
  if (actions.payload.type == 'inicial') {
    state.fecha_hora_inicial = actions.payload.date;
    return;
  }
  state.fecha_hora_final = actions.payload.date;
};

const resetDiarioSlice = (state: Draft<diarioVentasSliceType>) => {
  Object.assign(state, initialState);
  state.fecha_hora_inicial = dayjs().startOf('day').toDate();
  state.fecha_hora_final = dayjs().endOf('day').toDate();
};

const seleccionarFactura = (
  state: Draft<diarioVentasSliceType>,
  action: PayloadAction<number | null>,
) => {
  state.factura_seleccionada = action.payload;
};

const setFacturaCaja = (
  state: Draft<diarioVentasSliceType>,
  action: PayloadAction<boolean>,
) => {
  state.facturaCaja = action.payload;
};

const setEstadoFactura = (
  state: Draft<diarioVentasSliceType>,
  action: PayloadAction<number>,
) => {
  state.estado_factura = action.payload;
};

const setPagination = (
  state: Draft<diarioVentasSliceType>,
  action: PayloadAction<{
    current: number;
    total: number;
    last: number;
    per_page: number;

  }>,
) => {
  state.pagination = action.payload;
};

export const actions = {
  setDate,
  seleccionarFactura,
  resetDiarioSlice,
  setFacturaCaja,
  setEstadoFactura,
  setPagination,
};
