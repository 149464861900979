import {memo, useState} from 'react';
import isRoleAllowed from '../../common/isRoleAllowed';
// import CrudTable from '../../components/crud-table';
import {seleccionarFactura} from '../../redux/slices/diario-ventas-slice';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import AnularConfirmDialog from './anular-confirm-dialog';
// import { Typography } from '@mui/material';
import CrudTableVentas from '../../components/crud-table-ventas';

const TableContainer = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [anularFacID, setAnularFacID] = useState<number | null>(0);
  const dispatch = useAppDispatch();

  const facturas = useAppSelector(store => store.diarioVentasSlice.facturas);

  const estadoFactura = useAppSelector(
    store => store.diarioVentasSlice.estado_factura,
  );

  const facturaSeleccionada = useAppSelector(
    store => store.diarioVentasSlice.factura_seleccionada,
  );


    const totalC = useAppSelector(
      store => store.diarioVentasSlice.pagination.last,
    );

    const totalFilas = useAppSelector(
      store => store.diarioVentasSlice.pagination.per_page,
    );

  const facturaIndividual = facturaSeleccionada
    ? facturas.find(
        factura => factura.id_factura_sucursal === facturaSeleccionada,
      )
    : null;

  type columnType = {
    label: string;
    key: string[];
    showTotal?: boolean;
    type?: 'money' | 'porcentaje' | undefined;
  }[];

  const column = [
    {
      label: 'Factura',
      key: ['id_factura_sucursal'],
    },
    {
      label: 'Fecha',
      key: ['date'],
    },
    {
      label: 'Monto\u00A0(USD)',
      key: ['montoBruto'],
    },
    {
      label: '',
      key: ['id_factura_sucursal'],
    },
  ] as columnType;

  const handleClickAnular = async (id: number) => {
    setAnularFacID(id);
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
    setAnularFacID(null);
  };

  const handleOnSelected = (row: any) => {
    if (facturaIndividual?.id_factura_sucursal === row.id_factura_sucursal)
      return true;
    return false;
  };

  const onRowClick = (id: number) => dispatch(seleccionarFactura(id));

  return (
    <>
      <AnularConfirmDialog
        state={open}
        anularFacID={anularFacID}
        handleClose={handleCloseDialog}
      />
        {/* <Typography variant="body2">{`Total de registros: ${totalFilas} - ${totalC}`}</Typography> */}
      <CrudTableVentas
      
        noCard
        onRowClick={onRowClick}

        rows={facturas}
        columns={column}
        onAnular={
          isRoleAllowed([]) && estadoFactura ? handleClickAnular : undefined
        }
        isSelected={handleOnSelected}
      />
    </>
  );
};

export default memo(TableContainer);
