import {createSlice} from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import Utc from 'dayjs/plugin/utc';
import Timezone from 'dayjs/plugin/timezone';
import {anularFactura, fetchFactura, getFactura} from './diario-venta.thunk';
import {actions} from './diario-ventas.actions';
import {diarioVentasSliceType} from './diario-ventas.type';
dayjs.extend(Utc);
dayjs.extend(Timezone);

export const initialState = {
  factura_seleccionada: null,
  facturas: [],
  fecha_hora_inicial: null,
  fecha_hora_final: null,
  facturaCaja: true,
  estado_factura: 1,
  pagination: {
    current: 1,
    total: 0,
    last: 0,
    per_page: 0

  },
} as diarioVentasSliceType;

export const diarioVentasSlice = createSlice({
  name: 'diario-ventas-slice',
  initialState: initialState,
  reducers: {...actions},
  extraReducers: builder => {
    builder
      .addCase(fetchFactura.fulfilled, (state, action) => {
        state.facturas = action.payload.map(element => {
          const propinaTotal = element.propinas.reduce(
            (a, b) => a + b.cantidad,
            0,
          );
          const impuestoTotal = element.impuestos.reduce(
            (a, b) => a + b.cantidad,
            0,
          );

          const montoTotal = element.metodo_pago.reduce(
            (a, b) => a + b.cantidad,
            0,
          );

          return {
            date: dayjs(element.fecha_hora_factura)
              .tz('America/Panama')
              .format('YYYY-MM-DD hh:mm A'),
            impuestoTotal,
            propinaTotal,
            montoBruto: montoTotal + propinaTotal,
            montoNeto: montoTotal - impuestoTotal,
            ...element,
          };
        });
      })
      .addCase(anularFactura.fulfilled, (state, action) => {})
      .addCase(getFactura.fulfilled, (state, action) => {});
  },
});

export const {
  setDate,
  resetDiarioSlice,
  seleccionarFactura,
  setFacturaCaja,
  setEstadoFactura,
  setPagination,
} = diarioVentasSlice.actions;

export default diarioVentasSlice.reducer;
